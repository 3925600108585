<template>
<v-row class="mx-0">
    <v-col cols="12" sm="6" md="5" lg="3" 
           class="mx-auto text-center col-form-register mb-5">
    
    <h1 class="mb-5" style="font-size:50px;">
      {{ $store.state.app.siteName }}
      <span class="orange--text" style="font-weight:200">2042</span>
    </h1>
      <Register></Register>
    
      <v-divider/><br>
      Vous avez déjà un compte ?<br>
      <v-btn class="mt-3" outlined color="secondary" to="/login">
        <v-icon>mdi-login</v-icon> Connectez-vous
      </v-btn><br><br>
    </v-col>

    <v-col cols="12" class="pa-0">
      <Footer/>
    </v-col>

</v-row>
</template>

<script>

import '@/assets/css/home.css';

import Footer from '@/components/main-layout/Footer.vue'
import router from '../router/router'
import axios from 'axios'

const config = require('../config/' + process.env.NODE_ENV)
import Register from '@/components/main-layout/Register.vue'

export default {
  name: 'register',
  components: {
    Register, Footer
  },
  data: () => ({
    name: '',
    email: '',
    password: '',
    showpwd: false,
    errorRegister: false,
    rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 8 || 'Min 8 caractères',
          minName: v => v.length >= 3 || 'Min 3 caractères',
        },
  }),
  mounted: async function(){

    this.$store.dispatch('app/incStat', '/register')

    //console.log("config.enableRegisterAdmin", config, config.enableRegisterAdmin)
    if(config.enableRegisterUser == false) 
      router.push('/login')
    
    this.$store.dispatch('app/fetchNbUsersTotal')

    await this.$store.dispatch('app/fetchEntities', { entityType: 'plan', 
                                                      query : { "isActive": true },
                                                      sort: { position: 1 } 
                                                    })


    let planExist = false
    this.$store.state.app.data.plan.forEach(plan => {
      if(this.$route.params.planKey == plan.key) planExist = true
    })
    //si le plan indiqué dans l'url n'existe pas, et qu'il n'est pas vide : retourne sur l'acueil
    if(!planExist && this.$route.params.planKey != null) 
      router.push('/register')
  },
  methods: {
    async register(){
      //console.log("try to register", this.name, this.email, this.password)
      const res = await axios.post('/auth/register', 
                                    { name: this.name,
                                      email: this.email,
                                      password: this.password
                                    })
      //console.log("res /auth/register", res)
      if(res.data.error == false){
        //console.log("USER SAVED WITH SUCCES", res.data)
        this.errorRegister = false
        router.push('/login');
      }else{
        this.errorRegister = res.data.msg
      }
    }
  },
  computed: {
    planKeyIsValid: function(){ 
      if(this.$store.state.app.data.plan.length == 0) return true 

      let planExist = false
      this.$store.state.app.data.plan.forEach(plan => {
        if(this.$route.params.planKey == plan.key) planExist = true
      })
      return planExist
    }
  }
}
</script>
